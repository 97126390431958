function CategoryFilter(el) {
  const toggleButton = el.querySelector(".category-filter__select__button");
  const selectedCategory = el.querySelector(".category-filter__selected-category");
  const dropdown = el.querySelector(".category-filter__select__dropdown");
  const categoryListItems = el.querySelectorAll(".category-filter__select__dropdown__item");
  const clearButton = el.querySelector(".category-filter__clear-button");

  const modal = el.querySelector(".category-filter__dialog");
  const modalCloseButton = modal.querySelector(".category-filter__dialog__close-button");
  const modalClearButton = modal.querySelector(".category-filter__dialog__clear-button");
  const modalApplyButton = modal.querySelector(".category-filter__dialog__apply-button");
  const modalCategories = modal.querySelectorAll(".category-filter__dialog__category");

  let mobileQuery = window.matchMedia("(max-width: 767px)");

  toggleButton.addEventListener("click", toggle);
  document.addEventListener("click", closeOnOutsideClick);

  let _onCategoryChange = () => {};
  let _selectedCategoryId = "-1";

  const categories = [...categoryListItems].reduce((acc, li) => {
    acc[li.getAttribute("data-category-id")] = li.textContent;
    return acc;
  }, {});

  categoryListItems.forEach(listItem => {
    listItem.addEventListener("click", (el) => {
      close();
      const li = el.target;
      updateCategory(li.getAttribute("data-category-id"));
    });
  });

  clearButton.addEventListener("click", () => {
    updateCategory("-1");
  });

  modalCloseButton.addEventListener("click", () => {
    modal.close(_selectedCategoryId);
    close();
  });

  modalClearButton.addEventListener("click", () => {
    modal.close("-1");
    close();
  });

  modalApplyButton.addEventListener("click", () => {
    const category = [...modalCategories].find(li => li.classList.contains("category-filter__dialog__category--selected"));
    modal.close(category.getAttribute("data-category-id"));
    close();
  });

  modalCategories.forEach(li => {
    li.addEventListener("click", (el) => {
      const li = el.target;
      updateModalSelection(li.getAttribute("data-category-id"));
    });
  });

  modal.addEventListener("close", () => {
    updateCategory(modal.returnValue);
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  });

  function isOpen() {
    return el.classList.contains("category-filter--open");
  }

  function toggle() {
    el.classList.toggle("category-filter--open");
    toggleButton.setAttribute(
      "aria-expanded",
      toggleButton.getAttribute("aria-expanded") === "true" ? "false" : "true"
    );
    if (mobileQuery.matches) {
      showModal();
    }
  }
  
  function showModal() {
    updateModalSelection(_selectedCategoryId);
    const scrollY = window.scrollY;
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    modal.showModal();
  }
  
  function updateModalSelection(categoryId) {
    modalCategories.forEach(li => {
      if (li.getAttribute("data-category-id") === categoryId) {
        li.classList.add("category-filter__dialog__category--selected");
      } else {
        li.classList.remove("category-filter__dialog__category--selected");
      }
    });
  }
  
  function close() {
    el.classList.remove("category-filter--open");
    toggleButton.setAttribute("aria-expanded", "false");
  }

  function closeOnOutsideClick(event) {
    if (isOpen() && !(toggleButton.contains(event.target) || dropdown.contains(event.target))) {
      close();
    }
  }

  function updateCategory(id) {
    _selectedCategoryId = id;
    selectedCategory.textContent = categories[id];
    _onCategoryChange(id);
  }

  return {
    onCategoryChange(listener) {
      _onCategoryChange = listener;
    },
    clear() {
      updateCategory("-1");
    }
  }
}

export default CategoryFilter;