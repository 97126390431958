function OpenCampaign(el) {
  const learnMoreButton = el.querySelector(".campaign__learn-more-button");
  const learnMoreModal = el.querySelector(".campaign-learn-more-modal");
  const modalCloseButton = el.querySelector(".campaign-learn-more-modal__close-button");
  const tooltipButton = el.querySelector(".campaign-learn-more-modal__help-button");
  const tooltip = el.querySelector(".campaign-learn-more-modal__help-tooltip");

  learnMoreButton.addEventListener("click", () => {
    const scrollY = window.scrollY;
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    learnMoreModal.showModal();
  });

  learnMoreModal.addEventListener("close", () => {
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
  })

  modalCloseButton.addEventListener("click", () => {
    learnMoreModal.close();
  });

  learnMoreModal.addEventListener("click", (event) => {
    const rect = learnMoreModal.getBoundingClientRect();
    const isInDialog = (rect.top <= event.clientY && event.clientY <= rect.top + rect.height &&
      rect.left <= event.clientX && event.clientX <= rect.left + rect.width);
    if (!isInDialog) {
      learnMoreModal.close();
    }
  });

  let showTooptipTimeout = 0;
  let hideTooptipTimeout = 0;
  tooltipButton.addEventListener("mouseenter", () => {
    clearTimeout(hideTooptipTimeout);
    showTooptipTimeout = setTimeout(() => {
      tooltip.showPopover && tooltip.showPopover();
    }, 200);
  });

  tooltipButton.addEventListener("mouseleave", () => {
    clearTimeout(showTooptipTimeout);
    hideTooptipTimeout = setTimeout(() => {
      tooltip.hidePopover && tooltip.hidePopover();
    }, 200);
  });
}

export default OpenCampaign;