import U from "./Utilities";
import { gsap } from "gsap";

const duration = 0.85;
const ease = "Power2.easeInOut";

class ImageSlider {
  constructor(el) {
    U.autobind(this);

    this.dom = {
      el,
      track: el.querySelector(".image-slider__track"),
      slides: el.querySelectorAll(".image-slider__slide"),
      prev: el.querySelector(".image-slider__button--prev"),
      next: el.querySelector(".image-slider__button--next"),
      link: document.querySelector(".image-slider__link"),
    };

    this.slides = {
      current: 0,
      total: this.dom.slides.length,
    };

    this.timeline =  gsap.timeline();

    this._init();

    this.dom.next.addEventListener("click", () => {
      if (!this.timeline.isActive()) {
        this.next();
      }
    });
    this.dom.prev.addEventListener("click", () => {
      if (!this.timeline.isActive()) {
        this.prev();
      }
    });

    return this;
  }

  next() {
    this.changeSlide("next");
  }

  prev() {
    this.changeSlide("prev");
  }

  showCaptions() {
    this.captions.play();
  }

  _init() {
    this.dom.captions = U.parent(this.dom.el, "section").querySelectorAll(
      ".image-slider__slide--caption"
    );
    this.dom.texts = U.parent(this.dom.el, "section").querySelectorAll(
      ".image-slider__slide_text"
    );

    const initialLink = this.dom.captions[0].getAttribute("data-link");
    if (initialLink) {
      this.dom.link.setAttribute("href", initialLink);
    } else {
      this.dom.link.style.display = "none";
    }

    this.dom.captions.forEach((caption, i) => {
      caption.style.zIndex = 1;

      if (i) {
        caption.style.display = "none";
      }
    });

    this.captions =  gsap.timeline({
      paused: true,
    });

    this.captions
      .staggerFromTo(
        this.dom.captions[0].children,
        duration,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          ease: "Power3.easeOut",
        },
        0.1
      )
      .fromTo(
        this.dom.texts[0].children,
        duration,
        {
          y: 30,
        },
        {
          y: 0,
          ease: "Power3.easeOut",
        },
        `-=${duration}`
      );

    this.dom.slides.forEach((slide, i) => {
      slide.style.zIndex = 3;

      if (i) {
        slide.style.zIndex = 2;

        gsap.to(slide, 0, {
          xPercent: 100,
        });
      } else {
        slide.classList.add("image-slider__slide--current");
      }
    });
  }

  changeSlide(direction) {
    const currentSlide = this.dom.slides[this.slides.current];
    const currentCaption = this.dom.captions[this.slides.current];
    let offset = 100;

    switch (direction) {
      case "prev": {
        this.slides.current--;

        if (this.slides.current < 0) {
          this.slides.current = this.slides.total - 1;
        }

        break;
      }
      case "next": {
        offset *= -1;
        this.slides.current++;

        if (this.slides.current === this.slides.total) {
          this.slides.current = 0;
        }

        break;
      }
      default: {
        break;
      }
    }

    this.dom.link.style.display = "";
    this.timeline
      .fromTo(
        currentSlide,
        duration,
        {
          xPercent: 0,
        },
        {
          xPercent: offset / 2,
          ease,
        }
      )
      .fromTo(
        this.dom.slides[this.slides.current],
        duration,
        {
          xPercent: offset * -1,
        },
        {
          xPercent: 0,
          ease,
          onComplete: this.onComplete,
        },
        `-=${duration}`
      )
      .to(currentSlide, 0, {
        xPercent: offset,
        onComplete: () => {
          currentSlide.style.zIndex = null;
        },
      })
      .to(
        currentCaption,
        duration / 2,
        {
          opacity: 0,
          onComplete: () => {
            currentCaption.style.display = "none";
            currentCaption.style.opacity = null;

            this.dom.captions[this.slides.current].style.display = "flex";
          },
        },
      )
      .staggerFromTo(
        this.dom.captions[this.slides.current].children,
        duration,
        {
          opacity: currentCaption.getAttribute("data-link") ? 1 : 0,
        },
        {
          opacity: 1,
          ease: "Power3.easeOut",
          onComplete: this.onComplete,
        },
        0.05,
        `-=${duration / 2}`
      )
      .fromTo(
        this.dom.texts[this.slides.current].children,
        duration,
        {
          opacity: 0,
          y: 30,
        },
        {
          opacity: 1,
          y: 0,
          ease: "Power3.easeOut",
        },
        `-=${duration}`
      );

    var newSlide = this.dom.slides[this.slides.current];
    var newCaption = this.dom.captions[this.slides.current];
    newSlide.style.zIndex = 3;
    const updatedLink = newCaption.getAttribute("data-link");
    if (updatedLink) {
      this.dom.link.setAttribute("href", updatedLink);
    } else {
      this.dom.link.style.display = "none";
    }
  }

  setDimensions() {
    this.dom.items.forEach(slide => {
      slide.style.width = `${U.rect(this.dom.el).width}px`;
    });

    this.dom.track.style.width = `${this.dom.track.children[
      this.dom.track.children.length - 1
    ].offsetLeft + U.rect(this.dom.el).width}px`;

    this.dom.track.style.transform = `translate3d(-${
      this.dom.slides[this.slides.current].offsetLeft
    }px, 0, 0)`;
  }

  onComplete() {
    this.dom.slides[this.slides.current].style.zIndex = 2;
  }
}

export default ImageSlider;
