import U from "./Utilities";
import Expandable from "./Expandable";

class Menu {
  constructor(el) {
    this.dom = {
      el,
      toggle: document.getElementById("menu-toggle"),
      headerBar: document.getElementById("header-bar"),
      subnavToggle: el.querySelectorAll(".sub-nav__toggle"),
    };

    U.autobind(this);

    this.dom.subnavToggle.forEach(this._buildSubnav);
    this.dom.toggle.addEventListener("click", this.toggle);
    this.isIOS = U.client.isIOS();

    fetch("/immediate-opportunities-count").then(response => {
      response.json().then(data => {
        const count = data.count;
        const countTags = Array.from(document.querySelectorAll(".sub-nav__count-tag"));
        countTags.forEach(countTag => {
          countTag.innerHTML = `${count} OPEN`;
        });
      });
    })
  }

  toggle() {
    this.recalculateHeight();
    this.dom.el.classList.toggle("menu--is-visible");
    this.dom.headerBar.classList.toggle("header-bar--invert");
    this.dom.toggle.classList.toggle("menu__button--invert");
  }

  recalculateHeight() {
    if (this.isIOS) {
      const vh = window.innerHeight * 0.01;
      this.dom.el.style.setProperty('--vh', `${vh}px`);
    }
  }

  _buildSubnav(toggle) {
    const subnav = toggle.nextElementSibling;

    toggle.expandable = new Expandable(subnav, {
      duration: 0.5,
      ease: "Power3.easeInOut",
    });

    toggle.addEventListener("click", () => {
      this.dom.subnavToggle.forEach(item => {
        if (item !== toggle) {
          item.expandable.close();
        }
      });

      toggle.expandable.toggle();
    });
  }
}

export default Menu;
